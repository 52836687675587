export const dataTypes = [
    {
        label: 'int',
        value: 'int',
    },
    {
        label: 'uuid',
        value: 'uuid',
    },
    {
        label: 'text',
        value: 'text',
    },
    {
        label: 'timestamp',
        value: 'timestamp',
    },
    {
        label: 'float',
        value: 'float',
    },
    {
        label: 'decimal',
        value: 'decimal',
    },
    {
        label: 'md-object',
        value: 'md-object-dialog',
    },
];

export const dataTypesFormly = {
    int: 'number',
    uuid: 'input',
    text: 'input',
    timestamp: 'input',
    float: 'number',
    decimal: 'number',
    'md-object-dialog': 'md-object-dialog',
};

export const fieldTypes = [
    {
        label: 'standard',
        value: 0,
    },
    {
        label: 'partition',
        value: 1,
    },
    {
        label: 'clustering',
        value: 2,
    },
    {
        label: 'index',
        value: 3,
    },
];

export const dimensions = [
    {
        label: '',
        value: '',
    },
];
